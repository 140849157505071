<template>
  <div class="about">
    <h1>ABOUT</h1>

    <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean nec massa
      vitae neque vehicula consectetur nec at nibh. Cras tristique pellentesque
      diam, quis vulputate leo lacinia nec. Donec tristique felis a massa
      vehicula bibendum. Nullam auctor a metus at finibus. Sed in nisi id odio
      molestie pretium sit amet id sapien. Interdum et malesuada fames ac ante
      ipsum primis in faucibus. Duis nec ex ullamcorper, luctus metus eu, mollis
      risus.
    </p>

    <p>
      Pellentesque scelerisque ultricies lectus. Curabitur eleifend dui quis
      sapien blandit, sit amet tempor turpis varius. Curabitur bibendum massa
      nibh, eu tempor ipsum ornare sit amet. Quisque nec eros urna. In ipsum
      neque, dictum sit amet neque non, vestibulum vulputate nunc. Duis
      tincidunt luctus hendrerit. Maecenas vel congue orci. Praesent placerat
      erat ligula. Integer lacinia est at justo rutrum, eget tristique odio
      molestie.
    </p>

    <p>
      Integer gravida iaculis neque, sit amet consequat nulla. Mauris viverra
      posuere bibendum. Nam id ipsum id justo porttitor scelerisque sit amet vel
      ipsum. Ut suscipit vehicula velit quis suscipit. Proin semper lectus ex,
      non vestibulum lacus gravida sed. Donec pretium odio quis arcu mattis, eu
      aliquet lectus consectetur. Pellentesque scelerisque orci id justo
      fermentum elementum. Donec vitae libero nec augue tempus pharetra nec sed
      diam. Suspendisse faucibus semper ligula sed aliquet.
    </p>

    <p>
      Proin nibh enim, rhoncus ut mauris at, convallis rutrum ipsum. Suspendisse
      a augue ut ante consequat volutpat. Nulla bibendum, sem et cursus
      eleifend, massa enim sollicitudin lacus, sed placerat nisi enim maximus
      urna. Nam sit amet neque vel mauris ultricies tincidunt. Lorem ipsum dolor
      sit amet, consectetur adipiscing elit. Mauris viverra pellentesque arcu
      mattis iaculis. Nam porttitor vel diam porta hendrerit. Mauris urna sem,
      imperdiet sed velit ac, volutpat vulputate urna. Suspendisse felis risus,
      venenatis sed feugiat ac, pharetra vel mi.
    </p>

    <p>
      Quisque commodo, ipsum ut dictum euismod, mauris sem eleifend purus, vitae
      vulputate velit lacus et lacus. Morbi bibendum dignissim purus, nec
      imperdiet felis dictum quis. Nunc a pharetra purus. Nam ut massa at est
      suscipit auctor vitae vitae est. Mauris nibh nisl, convallis at dolor
      eget, aliquam hendrerit velit. Praesent nec felis accumsan, ultrices dolor
      eget, rhoncus sem. Nunc maximus non enim id aliquet. Phasellus finibus sit
      amet libero non luctus. Sed molestie dapibus turpis, quis cursus enim
      vehicula nec. Nullam sed leo mi. Duis quis mattis enim, nec efficitur
      dolor. Donec nibh metus, pretium eu dapibus ut, fermentum eget diam.
      Curabitur sit amet venenatis diam, sed consectetur est.
    </p>
  </div>
</template>